<template>
	<div class="m-con">
		<div class="m-head-bg"></div>
		<div class="m-main u-container">
			<div class="m-main-title">
				<span v-if="$route.params.type == 1">新闻资讯</span>
				<span v-else-if="$route.params.type == 2">最新资讯</span>
				<span v-if="$route.params.type == 3">培训信息</span>
			</div>
			<div class="m-list" v-loading="loading">
				<div class="m-item" v-for="(item,index) in list" @click="to_link('/detail',{id:item.id})">
					<div class="m-item-img">
						<img :src="item.cover" />
					</div>
					<div class="m-item-con u-m-l-80">
						<p class="m-item-font1 u-line-1 u-m-t-14">{{item.title}}</p>
						<div class="m-item-time u-m-t-18">
							<img src="../assets/img/common/icon-time2.png" />
							<span class="u-m-l-6">{{item.created_at}}</span>
						</div>
						<p class="m-item-font2 u-line-2 u-m-t-20">{{item.sub_detail}}</p>
					</div>
				</div>
			</div>
			<div class="u-flex u-row-center u-m-t-20 u-p-b-60">
				<el-pagination @current-change="pageChange" background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		to_link,
		to_redirect
	} from '../router/api.js'
	import {
		getNewsInformation
	} from "@/api/news.js"
	export default {
		data() {
			return {
				loading:false,
				params:{
					page:1,
					pageNum:10,
					status:''
				},
				list:[],
				total:0,
			}
		},
		mounted() {
			this.params.status = this.$route.params.type
			this.getList()
		},
		methods:{
			to_link,
			pageChange(e){
				this.params.page = e
				this.getList()
			},
			getList(){
				this.loading = true
				getNewsInformation(this.params).then(res => {
					setTimeout(() => {
						this.loading = false
					}, 500)
					if (res.code == 200) {
						this.total = res.count 
						this.list = res.data
					} else {
						this.$message.error(res.message)
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-con {
		width: 100%;
		height: 100%;

		padding-bottom: 100px;
    background: linear-gradient(to bottom,#11a6ff,#2bb0ff,#5bc2ff,#91d6ff,#cbecff,#fdffff) !important;

		.m-main {
			background-color: #fff;
			margin-top: 60px;

			.m-main-title {
				height: 64px;
				border-bottom: 1px solid #EEEEEE;
				font-size: 20px;
				line-height: 64px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #000000;
				padding-left: 38px;
			}
		}
	}

	.m-head-bg {
		width: 100%;
		min-width: 1200px;
		height: 430px;
		background: url(../assets/img/common/m-head-bg.png) no-repeat;
		background-size: cover;
		background-position: center 0;
	}

	.m-list {
		display: flex;
		flex-direction: column;
		padding: 30px 0;

		.m-item {
			display: flex;
			border-bottom: 1px dashed #F1F1F1;
			padding: 22px 10px;
			cursor: pointer;

			.m-item-font1 {
				font-size: 24px;
				line-height: 24px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #303030;
			}

			.m-item-font2 {
				line-height: 22px;
				font-size: 14px;
				color: #686868;
			}

			.m-item-img {
				width: 200px;
				height: 148px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.m-item-con {
				display: flex;
				flex-direction: column;
				width: 870px;

				.m-item-time {
					display: flex;
					align-items: center;
					font-size: 12px;
					color: #999999;
				}
			}
		}
	}
</style>
