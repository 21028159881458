import request from '@/utils/request.js'

// 新闻列表
export function getNewsInformation(query={}) {
  return request({
    url: 'api/getNewsInformation',
    method: 'POST',
    params: query
  })
}

// 新闻列表
export function getNewsInformationDetail(query={}) {
  return request({
    url: 'api/getNewsInformationDetail',
    method: 'POST',
    params: query
  })
}
